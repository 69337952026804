
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { Paper, TextField, Typography, Box, Button, Alert, AlertTitle, Grid, Link } from '@mui/material';
import Head from 'next/head';
import { useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import { LoadingButton } from '@mui/lab'
import bodyParser from 'body-parser';
import util from 'util';

import { GetPermissions, Login, RequestForgotPassword } from 'lib/session';
import { getDocumentCookie, setDocumentCookie } from 'src/helpers';
import useTranslation from 'next-translate/useTranslation';
import { LangName } from 'lib/utils';
import { GetUser } from 'lib/me';
import React from 'react';

const getBody = util.promisify(bodyParser.urlencoded({ extended: true }));

export default function PageLogin({
  postEmail = null,
  postPassword = null,
  postCoupon = null,
  postQuoteID = null,
  setUserDefaultLang,
  responseHandlerSnackbar,
  updatePermissions,
}) {
  const { t, lang } = useTranslation('page-login');
  const router = useRouter();

  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [userLocale, setUserLocale] = useState('en');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [requestForgotPasswordSuccess, setRequestForgotPasswordSuccess] = useState(false);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: postEmail ?? '',
      password: postPassword ?? ''
    }
  );

  // Is logged in
  // Use an effect to access the client-side session on the server side
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Set coupon in session
    if (postCoupon != null) {
      localStorage.setItem('coupon', postCoupon);
    }

    if (postQuoteID != null) {
      localStorage.setItem('quote_id', postQuoteID);
    }

    // GET param to logout
    if (router.query.logout) {
      setDocumentCookie('session_id', null);
    }

    setIsLoggedIn(getDocumentCookie('session_id') !== false && getDocumentCookie('session_id') !== null);

    if (postEmail != null) {
      handleSubmit();
    }
  }, [router.query.logout]);

  useEffect(() => {
    if (isLoggedIn) {
      // "Account setup"
      if (postQuoteID != null) {
        router.push('/setup');
      }
      else {
        router.push('/');
      }
    }
  }, [isLoggedIn]);

  const responseHandler = (response, data, isTimeout) => {
    // Login failure
    if (data.code == 1102) {
      setError(LangName(data, lang, 'message'));
    }

    responseHandlerSnackbar(response, data, isTimeout);
  };

  // Input change
  const handleInput = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  // Submit
  const handleSubmit = async (e) => {
    if (typeof (e) != 'undefined') e.preventDefault();

    if (inProgress) return;

    setInProgress(true);
    setError(false);

    Login({
      email: formInput.email,
      password: formInput.password,
      responseHandler
    }).then(session_id => {
      const isNowLoggedIn = session_id !== null;

      if (isNowLoggedIn) {
        // Get user permissions
        GetPermissions({ responseHandler: responseHandlerSnackbar }).then(permissions => {

          // Get user data - used to redirect to default lang
          GetUser({ responseHandler: responseHandlerSnackbar }).then(response => {
            const defaultLang = (response && response.default_lang) ? response.default_lang : 'en';

            setUserDefaultLang(defaultLang);

            setUserLocale(defaultLang);

            updatePermissions();

            setInProgress(false);

            setIsLoggedIn(isNowLoggedIn);
          });

        });
      }
      else {
        setInProgress(false);
      }
    });
  };

  const handleResetPassword = e => {
    setRequestForgotPasswordSuccess(null);

    RequestForgotPassword(formInput.email, responseHandlerSnackbar)
      .then(success => {
        if (success) {
          setRequestForgotPasswordSuccess(true);
        }
        else {
          setRequestForgotPasswordSuccess(false);
        }
      })
  }

  let content;

  if (isLoggedIn) {
    content = (
      <>
        <Typography className="my2">{t('logged_in_redirecting')}</Typography>
      </>
    );
  }
  else if (forgotPassword) {
    content = (
      <>
        <Box py={2}>
          <TextField
            label={t('email')}
            name="email"
            value={formInput.email}
            onChange={handleInput}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box py={2}>
          <LoadingButton
            variant="contained"
            loading={requestForgotPasswordSuccess === null}
            sx={{ marginRight: 1 }}
            onClick={handleResetPassword}
          >{t('reset_password')}</LoadingButton>

          <Button onClick={() => { setForgotPassword(false); }}>{t('back_to_login')}</Button>

          {requestForgotPasswordSuccess &&
            <Box py={2}>
              <Alert severity="success">{t('reset_password_success')}</Alert>
            </Box>
          }
        </Box>
      </>
    )
  }
  else {
    content = (
      <>
        <form onSubmit={handleSubmit}>
          <Box py={2}>
            <TextField
              label={t('email')}
              name="email"
              value={formInput.email}
              onChange={handleInput}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box py={2}>
            <TextField
              label={t('password')}
              type="password"
              name="password"
              defaultValue={formInput.password}
              onChange={handleInput}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box py={2}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={inProgress}
              sx={{ marginRight: 1 }}
            >{t('login_action')}</LoadingButton>

            <Button onClick={() => { setForgotPassword(true); }}>{t('forgot_password')}</Button>
          </Box>

          {error &&
            <Box py={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          }
        </form>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>{t('login')}</title>
      </Head>

      <Grid container spacing={2}>
        <Grid item md={'auto'}>
          <Paper className="p4">
            <Typography variant="h2" mb={2}>{t('login')}</Typography>

            {content}
          </Paper>
        </Grid>
        <Grid item md={'auto'}>
          <Paper className="p4">
            <Typography variant="h2" mb={2}>{t('no_account_title')}</Typography>

            <Typography mb={2}>{t('no_account_desc')}</Typography>
            <Button href={lang == 'fr' ? process.env.NEXT_PUBLIC_WEB_PRICING_URL_FR : process.env.NEXT_PUBLIC_WEB_PRICING_URL_EN}>{t('create_account')}</Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

 async function _getServerSideProps(context) {
  // Receiving POST login from website
  if (context.req.method == 'POST') {
    await getBody(context.req, context.res);
  }

  return {
    props: {
      postEmail: context.req.body?.email || null,
      postPassword: context.req.body?.password || null,
      postCoupon: context.req.body?.coupon || null,
      postQuoteID: context.req.body?.quote || null,
    }
  };
}

    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  